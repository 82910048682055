import { INITIAL_INVENTORY_ROOMS } from './inventory_items';
import { INITIAL_SPECIAL_INVENTORY_ITEMS } from './special_inventory_items';

export const SAME_DAY_BOOKING_WINDOW_HOURS = 3;
export const AFTER_HOURS_BLACKOUT_MINIMUM_HOUR = 10;
export const AFTER_HOURS_BUSINESS_CLOSE_HOUR = 21;
export const AFTER_HOURS_BUSINESS_TIMEZONE = 'America/New_York';
export const AFTER_HOURS_BUSINESS_OPEN_HOUR = 8;

export const INITIAL_CUSTOMER_INFO = {
  customer: { firstName: '', lastName: '', phone: '', email: '' },
  locations: [],
  inventory: { rooms: INITIAL_INVENTORY_ROOMS, additionalItems: INITIAL_SPECIAL_INVENTORY_ITEMS },
  gbIdentity: null,
  truck: null,
  date: null,
  time: null,
  inventoryModalAcknowledged: false,
  leadId: null,
  affiliateAccountId: null,
  selectedTemplate: null,
  marketingSource: {},
  metadata: {},
};
