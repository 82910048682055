export default ({ breakpoints, palette, spacing }) => ({
  '& .quote-flex-calendar .react-calendar': {
    fontFamily: ['Calibre', '-apple-system', 'system-ui', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(','),
    button: {
      margin: 0,
      border: 0,
      outline: 'none',
      fontFamily: ['Calibre', '-apple-system', 'system-ui', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(','),
    },
    'button:enabled:hover': {
      cursor: 'pointer',
    },
    '&__tile': {
      maxWidth: '100%',
      borderRadius: '50%',
      paddingTop: spacing(1),
      paddingBottom: spacing(1.5),
      paddingLeft: 0,
      paddingRight: 0,
      background: 'none',
      textAlign: 'center',
      fontSize: '1rem',
      lineHeight: 1,
      transition: 'all 0.15s ease-in-out 0s',
      [breakpoints.down('sm')]: {
        fontSize: '0.8rem',
      },
      abbr: {
        paddingBottom: spacing(0.5),
        display: 'inline-flex',
        color: palette.grey[900],
      },
      '&:disabled': {
        color: palette.grey[300],
        abbr: {
          color: palette.grey[300],
        },
        p: {
          color: palette.grey[300],
        },
      },
      '&:enabled:hover, &:enabled:focus': {
        background: palette.secondary.main,
        color: palette.grey[50],
        abbr: {
          color: palette.grey[50],
        },
        p: {
          color: palette.grey[50],
        },
      },
      '&--active, &--active:enabled:focus': {
        background: palette.secondary.main,
        color: palette.grey[50],
        abbr: {
          color: palette.grey[50],
        },
        p: {
          color: palette.grey[50],
        },
      },
    },
    '&__tile.no-price': {
      paddingTop: spacing(2),
      paddingBottom: spacing(1.5),
      fontSize: '1.25rem',
    },
    '&__navigation': {
      display: 'flex',
      height: 44,
      borderBottom: `1px solid ${palette.grey[300]}`,
      button: {
        minWidth: 44,
        background: 'none',
        borderRadius: '50%',
        '&:enabled:hover': {
          backgroundColor: palette.grey[100],
        },
      },
      '&__label': {
        color: palette.grey[800],
        fontSize: '1.68rem',
        fontWeight: 600,
        paddingBottom: spacing(1),
      },
    },
    '&__month-view': {
      '&__weekdays': {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '1.25rem',
        color: palette.grey[600],
        marginTop: spacing(1),
        marginBottom: spacing(2),
        abbr: {
          textDecoration: 'none',
        },
      },
    },
    '&__year-view': {
      '& .react-calendar__tile': {
        padding: '2em 0.5em',
      },
    },
  },
});
