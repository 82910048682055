export default ({ breakpoints, palette, spacing }) => ({
  '& .date-calendar .react-calendar': {
    fontFamily: ['Calibre', '-apple-system', 'system-ui', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(','),
    button: {
      margin: 0,
      border: 0,
      outline: 'none',
      fontFamily: ['Calibre', '-apple-system', 'system-ui', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(','),
      marginTop: spacing(0.5),
      marginBottom: spacing(0.5),
      [breakpoints.down('sm')]: {
        marginTop: spacing(0.5),
        marginBottom: spacing(0.5),
      },
      '&.react-calendar__tile': {
        border: '1px solid transparent',
      },
      '&.react-calendar__tile--now': {
        border: `1px solid ${palette.grey[400]}`,
      },
    },
    '&.react-calendar--doubleView .react-calendar__viewContainer': {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    'button:enabled:hover': {
      cursor: 'pointer',
    },
    '&__tile': {
      maxWidth: '100%',
      borderRadius: '50%',
      paddingTop: spacing(1.3),
      paddingBottom: spacing(1.3),
      paddingLeft: 0,
      paddingRight: 0,
      background: 'none',
      textAlign: 'center',
      fontSize: '1.35rem',
      lineHeight: 1,
      transition: 'all 0.15s ease-in-out 0s',
      [breakpoints.down('sm')]: {
        fontSize: '1.35rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      },
      abbr: {
        color: palette.grey[900],
      },
      '&:enabled:hover, &:enabled:focus': {
        background: palette.secondary.main,
        color: palette.grey[50],
        abbr: {
          color: palette.grey[50],
        },
        p: {
          color: palette.grey[50],
        },
      },
      '&--active, &--active:enabled:focus': {
        background: palette.secondary.main,
        color: palette.grey[50],
        abbr: {
          color: palette.grey[50],
        },
        p: {
          color: palette.grey[50],
        },
      },
      '&:disabled': {
        color: palette.grey[300],
        abbr: {
          color: palette.grey[300],
        },
        p: {
          color: palette.grey[300],
        },
      },
      '&--active:disabled': {
        background: palette.grey[200],
        color: palette.grey[400],
        abbr: {
          color: palette.grey[400],
        },
        p: {
          color: palette.grey[900],
        },
      },
    },
    '&__navigation': {
      display: 'flex',
      height: 44,
      button: {
        minWidth: 44,
        background: 'none',
        borderRadius: '50%',
        '&:enabled:hover': {
          backgroundColor: palette.grey[100],
        },
      },
      '&__label': {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        color: palette.grey[800],
        fontSize: '1.68rem',
        fontWeight: 600,
        paddingBottom: spacing(1),
        '&__labelText': {
          flex: 2,
        },
        '&__divider': {
          flex: 1,
          visibility: 'hidden',
        },
      },
    },
    '&__month-view': {
      paddingLeft: spacing(5),
      paddingRight: spacing(5),
      [breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '&__weekdays': {
        borderBottom: `1px solid ${palette.grey[300]}`,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '1.25rem',
        color: palette.grey[400],
        paddingBottom: spacing(1),
        marginTop: spacing(1),
        marginBottom: spacing(1),
        abbr: {
          textDecoration: 'none',
        },
      },
    },
    '&__year-view': {
      '& .react-calendar__tile': {
        padding: '2em 0.5em',
      },
    },
  },
});
